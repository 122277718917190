<template>
  <v-container fluid style="padding: 16px 32px">
    <v-card
      class="mx-auto"
      outlined
    >
      <v-card-subtitle style="color: #FFFFFF;padding-left: 12px">
        <v-container fluid>
          <v-row>
            <v-col>
              <h2>Dane kontaktowe:</h2>
              <br>
              <v-container fluid>
                <v-row>
                  <v-col md="auto">
                    <v-icon left color="secondary">{{ contactDetails.email.icon }}</v-icon>
                    <span>Email:</span>
                  </v-col>
                  <v-col md="auto"><span>{{ contactDetails.email.text.join('') }}</span></v-col>
                </v-row>
                <v-row>
                  <v-col md="auto">
                    <v-icon left color="secondary">{{ contactDetails.phone.icon }}</v-icon>
                    <span>Telefon:</span>
                  </v-col>
                  <v-col md="auto"><span>{{ contactDetails.phone.text.join('') }}</span></v-col>
                </v-row>
                <v-row>
                  <v-col md="auto">
                    <v-icon left color="secondary">{{ contactDetails.address.icon }}</v-icon>
                    <span>Adres:</span>
                  </v-col>
                  <v-col md="auto"><span v-for="adressLine in contactDetails.address.text">{{ adressLine.join('') }}<br></span></v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col>
              <h2>Napisz do nas:</h2>
              <br>
              <ContactForm ref="contactForm" :form-details="formDetails"></ContactForm>
              <br>
              <div style="text-align: right">
                <v-btn color="tertiary" @click="submitForm">Wyślij</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>
<script>
import ContactForm from '../components/ContactForm'

export default {
  inheritAttrs: false,
  name: 'contact',
  props: ['contactDetails', 'formDetails'],
  data: function () {
    return {}
  },
  mounted: function () {
  },
  components: {
    ContactForm
  },
  computed: {},
  methods: {
    submitForm: function () {
      this.$refs.contactForm.sendMail()
    }
  }
}
</script>
<style scoped>

</style>
